// Entry point for the build script in your package.json

require("@rails/ujs").start()
//require("turbolinks").start()
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

//require("channels")

import { Helpers } from './src/helpers.js'
window.Helpers = Helpers

import './controllers/app'
import gtag from './src/analytics'