import { Controller } from '@hotwired/stimulus'
const GUMROAD_SCRIPT = "https://gumroad.com/js/gumroad.js"

export default class extends Controller {
  static targets = ["checkout"]

  initialize() {
    if (!this.gumroadScriptLoaded()) {
      this.loadGumroadScript()
    } else {
      this.reinitializeGumroadScript()
    }
  }

  disconnect() {
    window.GumroadOverlay = null
    this.element.removeAttribute('data-controller')
  }

  loadGumroadScript() {
    let script = document.createElement('script')
    document.head.appendChild(script)

    // If we are visiting a page with Gumroad script for first time via a Turbo Visit, we need to manually run Gumroad overlay function.
    if (document.readyState === "complete") {
      // Give time for GumroadOverlay Script to load before running CreateGumroadOverlay
      setTimeout(() => {createGumroadOverlay()}, 550)
    }

    script.type = "text/javascript"
    script.src = GUMROAD_SCRIPT
    this.markGumroadScriptAsLoaded()
  }

  gumroadScriptLoaded() {
    return document.documentElement.hasAttribute("data-gumroad-loaded")
  }

  markGumroadScriptAsLoaded() {
    document.documentElement.setAttribute("data-gumroad-loaded", true)
  }

  reinitializeGumroadScript() {
    this.reinitializeAllCheckoutButtons()
    this.removeOldGumroadStylesheet()
    this.removeOldGumroadElements()
    createGumroadOverlay()
  }

  // In order to detach old gumroad event listeners and prevent gumroad from duplicating buttons we need to wipe all buttons and reinitialize them
  reinitializeAllCheckoutButtons() {
    this.checkoutTargets.forEach(function(checkoutTarget) {
      let clone = checkoutTarget.cloneNode()
      clone.appendChild(document.createTextNode(checkoutTarget.lastChild.nodeValue))
      checkoutTarget.parentNode.replaceChild(clone, checkoutTarget)
    })
  }

  removeOldGumroadElements() {
    let oldLoadingIndicator = document.querySelector('.gumroad-loading-indicator')
    let oldScrollContainer = document.querySelector('.gumroad-scroll-container')

    if (oldLoadingIndicator) {
      oldLoadingIndicator.remove()
    }

    if (oldScrollContainer) {
      oldScrollContainer.remove()
    }
  }

  removeOldGumroadStylesheet() {
    let stylesheets = document.querySelectorAll('style')
    stylesheets.forEach(function(stylesheet) {
      if (stylesheet.innerText.match("gumroad-button")) {
        stylesheet.remove()
      }
    })
  }
}